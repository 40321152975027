import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { getPageLink } from '../constants';
import homeImg01 from '../images/home-img01.jpg';
import homeImg02 from '../images/home-img02.jpg';
import homeImg03 from '../images/home-img03.jpg';
import './WelcomePage.scss';
import { PageContentType } from '../context/PageContentType';

/**
 * Welcome page
 *
 * @param props
 * @constructor
 */
const WelcomePage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'WELCOME_PAGE')}
      pageTitle={t('welcomePage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          {/*<h1>{t('welcomePage.pageTitle')}</h1>*/}
          {/*<p>{t('welcomePage.pageDescription')}</p>*/}
          {/*{authState?.isAuthenticated && userInfo && (*/}
          {/*  <>*/}
          {/*    <p>You are signed in.</p>*/}
          {/*    <pre>{JSON.stringify(userInfo, null, 4)}</pre>*/}
          {/*    <button*/}
          {/*      type='button'*/}
          {/*      className='ontario-button ontario-button--primary'*/}
          {/*      onClick={logout}*/}
          {/*    >*/}
          {/*      Log out*/}
          {/*    </button>*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{authState && !authState.isAuthenticated && (*/}
          {/*  <button*/}
          {/*    type='button'*/}
          {/*    className='ontario-button ontario-button--primary'*/}
          {/*    onClick={login}*/}
          {/*  >*/}
          {/*    Log in*/}
          {/*  </button>*/}
          {/*)}*/}

          <div className="mdmp__onboarding">
            {/*<Callout*/}
            {/*  lang={props.pageContext.lang}*/}
            {/*  title={t('callout.onBoardingCallout.title')}*/}
            {/*  linkTitle={*/}
            {/*    <Link to={'#'}>*/}
            {/*      {t('callout.onBoardingCallout.titleLinkText')}*/}
            {/*    </Link>*/}
            {/*  }*/}
            {/*>*/}
            {/*  <p>*/}
            {/*    <Trans i18nKey={'callout.onBoardingCallout.desc'}>*/}
            {/*      Part1 <Link to={'#'}>Part2</Link> Part3*/}
            {/*    </Trans>*/}
            {/*  </p>*/}

            {/*  <div className={'mdmp__callout-cta'}>*/}
            {/*    <button*/}
            {/*      className="ontario-button ontario-button--secondary"*/}
            {/*      onClick={() =>*/}
            {/*        navigate(getPageLink(props.pageContext.lang, 'HOME_PAGE'))*/}
            {/*      }*/}
            {/*    >*/}
            {/*      {t('callout.onBoardingCallout.buttonText')}*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</Callout>*/}

            <div className={'mdmp__home-main-block ontario-row'}>
              <div className={'ontario-columns ontario-small-12'}>
                <h1>{t('welcomePage.homeMainBlock.title')}</h1>
                <img alt={'home image 01'} src={homeImg01} />

                <p>
                  <Trans i18nKey={'welcomePage.homeMainBlock.desc'}>
                    Part1{' '}
                    <Link
                      className={'mdmp__bold'}
                      to={getPageLink(
                        props.pageContext.lang,
                        'ALL_PRODUCTS_PAGE',
                      )}
                    >
                      Part2
                    </Link>
                  </Trans>
                </p>
              </div>
            </div>

            <hr className={'mdmp__hr-black'} />

            <div className={'mdmp__home-sub-blocks ontario-row'}>
              <div
                className={'ontario-columns ontario-small-12 ontario-medium-6'}
              >
                <img alt={'home image 02'} src={homeImg02} />

                <h2>{t('welcomePage.homeSecondaryBlocks.block01.title')}</h2>

                <p>
                  <Trans
                    i18nKey={'welcomePage.homeSecondaryBlocks.block01.desc'}
                  >
                    Part1 <Link to={'#'}>Part2</Link> Part3
                  </Trans>
                </p>
              </div>
              <div
                className={'ontario-columns ontario-small-12 ontario-medium-6'}
              >
                <img alt={'home image 03'} src={homeImg03} />

                <h2>{t('welcomePage.homeSecondaryBlocks.block02.title')}</h2>

                <p>
                  <Trans
                    i18nKey={'welcomePage.homeSecondaryBlocks.block02.desc'}
                  >
                    Part1{' '}
                    <Link to={t('common.aris.url') + '#aris-url'}>Part2</Link>{' '}
                    Part3
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default WelcomePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
